<template>
    <section :data-sec-scroll="L2.Title" :class="['nkg__section', L2.Styles, { 'lang-en': this.$route.params.lang === 'en-US'}]">
        <div class="nkg__container">
        </div>
        <div class="nkg__container">
            <h2 v-if="L2.Title!==''" class="h3 text-center fw-bold" v-html="L2.Title"></h2>
            <div class="nkg__wrap">

                <Form class="row justify-content-center text-body" name="form" ref="form" @submit.prevent="onSubmit">

                    <div class="user-info">
                        <div class="row px-0">

                            <div class="col-md-6">
                                <div class="form-floating mb-3 mb-md-4 required">
                                    <Field ref="inputField" type="text" name="ReportIdentity" class="d-none" v-model="ReportIdentity" :rules="'is_not:'+ChLangData('Select')"/>
                                    <CustomSelect
                                        class="required"
                                        :options="ReportIdentityItemData"
                                        :default="updateReportIdentity"
                                        :label="ChLangData('S1')"
                                        @input-value="ReportIdentity=$event"
                                    />
                                    <ErrorMessage v-show="isChanged" class="error smallest text-warning" name="ReportIdentity" />
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-floating mb-3 mb-md-4 required">
                                    <Field ref="inputField" type="text" name="ReportCategory" class="d-none" v-model="ReportCategory" :rules="'is_not:'+ChLangData('Select')"/>
                                    <CustomSelect
                                        class="required"
                                        :options="ReportCategoryItemData"
                                        :default="updateReportCategory"
                                        :label="ChLangData('S2')"
                                        @input-value="ReportCategory=$event"
                                    />         
                                    <ErrorMessage v-show="isChanged" class="error smallest text-warning" name="ReportCategory" />
                                </div>
                            </div>
                        </div>

                        <div class="col-12 text-white mb-2" v-i18n="'Please enter your name and contact information:'"></div>

                        <div class="row px-0">
                            <div class="col-12 col-md-4">
                                <div class="form-floating mb-3 mb-md-4">
                                    <Field type="text" name="Name" class="form-control border-0" id="Name" v-model="Name" :placeholder="ChLangData('Name')" maxlength="32" rules="" />
                                    <label for="Name" v-i18n="'Name'"></label>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="form-floating mb-3 mb-md-4">
                                    <Field type="tel" name="Phone" class="form-control border-0" id="Phone" v-model="Phone" :placeholder="ChLangData('Phone')" maxlength="32" rules="" />
                                    <label for="Phone" v-i18n="'TEL'"></label>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="form-floating mb-3 mb-md-4 required">
                                    <Field ref="inputField" type="email" name="Email" class="form-control border-0" id="Email" v-model="Email" :placeholder="ChLangData('E-mail')" maxlength="32" rules="required|email" />
                                    <label for="Email" v-i18n="'E-mail'"></label>
                                    <ErrorMessage class="error smallest text-warning" name="Email" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="report-info">

                        <div class="col-12 text-white mb-3" v-i18n="'Please provide the reporting event, time and specific content:'"></div>
                        
                        <div class="row px-0">
                            <div class="col-12 col-md-6">
                                <div class="form-floating mb-3 mb-md-4 required">
                                    <Field ref="inputField" type="text" name="ReportPlace" class="form-control border-0" id="ReportPlace" v-model="ReportPlace" :placeholder="ChLangData('ReportPlace')" maxlength="32" rules="required" />
                                    <label for="ReportPlace" v-i18n="'R1'"></label>
                                    <ErrorMessage class="error smallest text-warning" name="ReportPlace" />
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-floating mb-3 mb-md-4 required">
                                    <Field ref="inputField" type="text" name="ReportTime" class="form-control multiline-h-control border-0" id="ReportTime" v-model="ReportTime" :placeholder="ChLangData('ReportTime')" maxlength="32" rules="required" />
                                    <label for="ReportTime" class="paddingtop-control" v-i18n="'R2'"></label>
                                    <ErrorMessage class="error smallest text-warning" name="ReportTime" />
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-12 text-white mb-3" v-i18n="'Please list the Kinpo Electronics personnel involved in the report:'"></div>

                        <div class="row px-0">
                            <div class="col-12 col-md-6">
                                <div class="form-floating mb-3 mb-md-4 required">
                                    <Field ref="inputField" type="text" name="ReportObject" class="form-control border-0" id="ReportObject" v-model="ReportObject" :placeholder="ChLangData('ReportObject')" maxlength="32" rules="required" />
                                    <label for="ReportObject" v-i18n="'R3'"></label>
                                    <ErrorMessage class="error smallest text-warning" name="ReportObject" />
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-floating mb-3 mb-md-4 required">
                                    <Field ref="inputField" type="text" name="ReportOrg" class="form-control border-0" id="ReportOrg" v-model="ReportOrg" :placeholder="ChLangData('ReportOrg')" maxlength="32" rules="required" />
                                    <label for="ReportOrg" v-i18n="'R4'"></label>
                                    <ErrorMessage class="error smallest text-warning" name="ReportOrg" />
                                </div>
                            </div>
                        </div>

                        <hr style="background: #00c6ff;">

                        <div class="col-12 mt-4">
                            <div class="form-floating mb-3 mb-md-4 required">
                                <Field ref="inputField" type="text" name="ReportIssue" class="form-control border-0" id="ReportIssue" v-model="ReportIssue" :placeholder="ChLangData('ReportIssue')" maxlength="32" rules="required" />
                                <label for="ReportIssue" v-i18n="'R5'"></label>
                                <ErrorMessage class="error smallest text-warning" name="ReportIssue" />
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="form-floating required textarea">
                                <Field ref="inputField" as="textarea" name="ReportContent" class="form-control border-0" placeholder=" " id="ReportContent" v-model="ReportContent" style="min-height: 200px" rules="required"></Field>
                                <label for="ReportContent" v-i18n="'R6'"></label>
                                <ErrorMessage class="error smallest text-warning" name="ReportContent" />
                            </div>
                        </div>

                        <!-- input upload files -->
                        <div class="col-12 col-md-3 mt-4 position-relative w-100">
                            <div class="form-floating mb-3 mb-md-4 position-relative">
                                <div class="form-control height-control position-absolute top-0 bottom-0"></div>
                                <Field id="UploadFile" type="file" name="UploadFile" ref="fileInput" title="" lang="zh-TW" accept=".doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .zip, .rar, .gif, .png, .jpg, .jpeg, .pdf, .mp3, .mp4" class="form-control border-0" style="opacity: 0" :placeholder="ChLangData('UploadFile')" rules="" multiple @change="onFilePicked" />
                                
                                <label for="'UploadFile" class="pe-4 paddingtop-control d-flex align-items-center justify-content-between w-100" style="transform: inherit;">
                                    <span class="d-block pe-2" v-i18n="'R7'"></span>
                                    <div class="d-flex">
                                        <span class="d-none d-md-block" v-i18n="'upload'"></span>
                                        <i class="nkg__ic upload" style="width: 24px; height: 24px"></i>
                                    </div>
                                </label>
                            </div>
                        </div>

                        <!-- show upload files -->
                        <ul v-show="showUploads" class="files-list">   
                            <li v-for="(item, index) in filesListFresh" :key="item" class="filebox">
                                <span>{{ item.name }} </span>
                                <button type="button" class="btn-remove" @click.prevent="deleteItem" :value="index">X</button>
                            </li>
                        </ul>

                        <ol class="text-white">
                            <li v-i18n="'Upload limit 1'"></li>
                            <li v-i18n="'Upload limit 2'"></li>
                        </ol>

                    </div>
                    <!--Submit -->
                    <div class="nkg__wrap text-center">
                        <button type="submit" class="d-inline-flex justify-content-between align-items-center btn btn-outline-primary rounded-pill text-start" @click="validateBeforeSubmit">
                            <span></span>
                            <span v-i18n="'Submit'"></span><svg-icon name="arrow-r" size="24" />
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    </section>
    <div v-if="isCollapse" class="modal-backdrop show d-flex justify-content-center align-items-center" style="z-index:1052;">
        <div class="spinner-border text-light" role="status" style="width: 3rem; height: 3rem;">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>

    <!-- 舉報信箱注意事項 -->
    <Popup v-if="isOpenPopup" :show="isOpenPopup">
        <div class="scrollBox">
            <h2 class="popup__title" v-i18n="'Kinpo Report Mailbox'"></h2>
            <p v-i18n="'Report Mailbox Rule P1'"></p>
            <p v-i18n="'Report Mailbox Rule P2'"></p>
            <ol class="rule mb-4">
                <li v-i18n="'Report Mailbox Rule li_1'"></li>
                <li v-i18n="'Report Mailbox Rule li_2'"></li>
                <li v-i18n="'Report Mailbox Rule li_3'"></li>
                <li v-i18n="'Report Mailbox Rule li_4'"></li>
                <li v-i18n="'Report Mailbox Rule li_5'"></li>
                <li v-i18n="'Report Mailbox Rule li_6'"></li>
            </ol>

            <label class="d-flex align-items-start">
                <input ref="Accept" type="checkbox" name="Accept" class="custom-checkbox me-2" v-model="AcceptRules" rules="required" />
                <span class="text-danger"> ＊ </span><span :class="{ 'text-danger': activeTip, 'text-black': AcceptRules }" v-i18n="'Accept Report Rules'"></span>
            </label>
        </div>

        <div class="nkg__wrap text-center mt-3">
            <button type="button" class="btn-size-240 d-inline-flex justify-content-between align-items-center btn btn-outline-primary rounded-pill text-start" @click="goalkeeper">
                <span></span>
                <span v-i18n="'Goto Report Mailbox form'"></span><svg-icon name="arrow-r" size="24" />
            </button>
        </div>
    </Popup>

    <!-- 錯誤訊息 -->
    <Popup class="feedback-popup" v-show="isOpenErrorFeedback" :show="isOpenErrorFeedback" :showErrorPopup="isOpenErrorFeedback" @close="isOpenErrorFeedback = false">
        <p class="text-danger">
            <span v-i18n="'Error Feedback p_1'"></span><br>
            <span v-i18n="'Error Feedback p_2'"></span>
        </p>
        <!-- <div class="nkg__wrap text-center">
            <div class="btn-size-240 d-inline-flex justify-content-center align-items-center btn btn-outline-primary rounded-pill text-center" @click="isOpenErrorFeedback = false"><span v-i18n="'Close'"></span></div>
        </div> -->
    </Popup>

    <!-- 成功訊息 -->
    <Popup class="feedback-popup" v-show="isOpenSuccessFeedback" :show="isOpenSuccessFeedback">
        <p>
            <span v-i18n="'Success Feedback p_1'"></span><br>
            <span v-i18n="'Success Feedback p_2'"></span><br>
            <span v-i18n="'Success Feedback p_3'"></span>
        </p>
        <div class="nkg__wrap text-center">
            <router-link class="btn-size-240 d-inline-flex justify-content-between align-items-center btn btn-outline-primary rounded-pill text-start" :to="'/' +this.$route.params.lang +'/home'">
                <span></span><span v-i18n="'Goto Homepage'"></span><svg-icon name="arrow-r" size="24" />
            </router-link>
        </div>
    </Popup>
</template>

<script>
import { ref, onMounted } from "vue";
import $ from "jquery";
import kernel from "../plugins/kernel";
import CustomSelect from "@/components/lib/CustomSelect.vue";
import Popup from "@/components/lib/Popup.vue";
import { Field, Form, ErrorMessage, defineRule, configure } from 'vee-validate';
import AllRules from '@vee-validate/rules';
import { localize, setLocale } from '@vee-validate/i18n';
import en from '@vee-validate/i18n/dist/locale/en.json';
import zh_TW from '@vee-validate/i18n/dist/locale/zh_TW.json';

Object.keys(AllRules).forEach(rule => {
    defineRule(rule, AllRules[rule]);
});
configure({   
    generateMessage: localize({
        zh_TW: {
            messages: {
                required: '此為必填欄位',
                is_not: '此為必填欄位',
                email: '不是正確的電子郵件',
                min: '不能小於 0:{min} 個字元',
            },
        },
        en: {
            messages: {
                required: 'This is a required field',
                is_not: 'This is a required field',
                email: 'Not a correct email',
                min: 'Cannot be less than 0:{min} characters',
            },
        },
    }),
});

export default {
    props: {
        pageindex: [Number, String],
        L2: Object
    },
    components: {
        CustomSelect,
        Popup,
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        return {
            ReportIdentityItemData: [],
            ReportCategoryItemData: [],
            ReportIdentityData:[],
            ReportCategoryData:[],
            ReportIdentityGroupItem:[],
            ReportIdentity: '',
            ReportCategory: '',
            Name: '',
            Phone: '',
            Email: '',
            ReportPlace: '',
            ReportTime: '',
            ReportObject: '',
            ReportOrg: '',
            ReportIssue: '',
            ReportContent: '',
            UploadFile_1: null,
            UploadFile_2: null,
            UploadFile_3: null,
            UploadFile_4: null,
			// submitRandom: Math.random(),
            isChanged: false,
            isCollapse: false,
            ReportCategoryDataTmp:[],
            ReportCategoryEmail:[],
            activeTip: false,
            //上傳檔案
            file: null,
            showUploads: false,
            filePath: "https://www.kinpo.com.tw/kpo_file/",
            filesList: null,
            filesListFresh: null,
            renameUserFiles: [],
            //開關視窗
            isOpenPopup: true,
            AcceptRules: null,
            isOpenSuccessFeedback: false,
            isOpenErrorFeedback: false,
        };
    },
    setup() {
        const {ChLangData, LangData, getLang} = kernel();
        LangData(getLang.value);

        const Accept = ref(null);
        const inputField = ref(null);

        return {
            ChLangData,
            Accept,
            inputField,
        }; 
    },
    mounted() {
        let L=this.$route.params.lang;
        let {ACWSCFG,CfgData} = kernel();
        let _t=this;
        CfgData('{"class":"kpo-report-mailbox","lang":"'+L+'"'+ ACWSCFG.value +'}').then(r => {
            _t.ReportIdentityItemData=Object.keys(r.ReportIdentity);
            _t.ReportCategoryItemData=Object.keys(r.ReportCategory);
            Object.keys(r.ReportCategory).forEach(function(v,k){
                _t.ReportCategoryEmail[r.ReportCategory[v].ValA] = r.ReportCategory[v].ValA_Name;
            });
            _t.ReportCategoryDataTmp=r.ReportCategory;
        });
        if(L == 'zh-TW'){
            setLocale('zh_TW');
        } else if(L == 'en-US'){
            setLocale('en');
        }

        this.switchOverflowScroll();
    },
    computed: {
        updateReportIdentity() {
            if(this.ReportIdentity!==''){
                return this.ReportIdentity;
            } else {
                return this.ChLangData('Select');
            }
        },
        updateReportCategory() {
            if(this.ReportCategory!==''){
                return this.ReportCategory;
            } else {
                return this.ChLangData('Select');
            }
        }
    },
    methods: {
        switchOverflowScroll() {
            //鎖 body 捲動
            // if (this.isOpenPopup == true) {
            //     $("html, body").css("overflow-y", "hidden");
            // } else {
            //     $("body").css("overflow-y", "scroll");
            //     $("html").css("overflow-y", "inherit");
            // }
        },
        goalkeeper() {
            let unfilledField = this.$refs.Accept;
            console.log("this.$refs.Accept unfilledField 是什麼 ====>", unfilledField);

            if (unfilledField) {
                unfilledField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }

            if (this.AcceptRules) {
                this.isOpenPopup = false;
            } else {
                this.activeTip = true;
                this.isOpenPopup = true;
            }
            this.switchOverflowScroll();
        },
        onFilePicked(e) {
            const files = e.target.files;

            if (files.length > 4) {
                let sendMsg = this.$route.params.lang=='zh-TW' ? '最多可上傳 4 個檔案' : 'Up to 4 files can be uploaded';
                alert(sendMsg);	
                return;
            }

            this.showUploads = true;
            this.filesList = files;
            this.filesListFresh = files;
        },
        uploadFile() {
            if (this.filesList != null ) {
                const fd = new FormData();

                for (var i = 0; i < this.filesList.length; i++) {
                    //創檔案
                    const createFile = (bits, name, options) => {
                        try {
                            // If this fails, we go for Blob
                            return new File(bits, name, options);
                        } catch (e) {
                            // If we're here a new File could not be constructed
                            var myBlob = new Blob(bits, options || {});
                            myBlob.lastModified = new Date();
                            myBlob.name = name;
                            return myBlob;
                        }
                    };
                    //新檔名
                    const newFF = "UserFile_"+(+new Date())+"."+this.filesList[i].name.split(".")[1]
                    console.log('newFF=======>',typeof newFF, newFF);
                    //新檔案
                    const renameFile = createFile([this.filesList[i]], newFF, {type: this.filesList[i].type});
                    console.log("renameFile==============>", renameFile);
                    const newUserFiles = [];

                    const obj = {};
                    obj = renameFile;
                    this.renameUserFiles.push(obj);

                    if (this.renameUserFiles[i] != 'undefined' && this.renameUserFiles[i] != '' ) {
                        fd.append('File[' + i + ']', this.renameUserFiles[i]);//File 首字一定要大寫
                        console.log('this.renameUserFiles[i]=======>',this.renameUserFiles[i]);

                        const n = "UploadFile_"+(i+1);
                        this[n] = this.filePath+this.renameUserFiles[i].name;
                    } else {
                        this[n] = "";
                    }
                }

                const dir = "https://nkg-web-api.japaneast.cloudapp.azure.com/Admin/UP/?Name="+this.renameUserFiles[0].name+"&mid=0&mclass=BC&lang=BC&class=TEST";

                console.log('dir',dir);
                this.axios({
                    method: 'post',
                    url: dir,
                    data: fd,
                    headers: {'Content-Type': 'multipart/form-data' }
                })
                .then(response => {
                    if(response.status == 200){
                        let sendMsg = this.$route.params.lang==='zh-TW' ? '檔案送出成功' : 'Sent successfully';
                        //alert(sendMsg);
                    } else {	
                        let sendMsg = this.$route.params.lang==='zh-TW' ? '檔案送出失敗' : 'Failed to send';
                        //alert(sendMsg);	
                    }
                })
                .catch(error => {
                    // console.log(error);
                });
            }
        },
        checkFileSize(file) {
            const validFileSize = 25 * 1024 * 1024;

            var fileSize = file.size;
            var isValidFile = false;
            if (fileSize !== 0 && fileSize <= validFileSize) {
                isValidFile = true;
            }
            else {
                file.value = null;
                alert("上傳檔案大小不能超過 25MB!");
            }
            return isValidFile;

        },
        deleteItem(e) {
            let parentBox = e.target.parentNode;

            console.log("e===>", e);
            console.log("e.target.parentNode===>", e.target.parentNode);
            
            parentBox.classList.toggle('hidden');
            setTimeout(() => {
                parentBox.style.display = 'none';
            }, 600);

            let jsonObj = this.filesList;

            var obj = Object.keys(jsonObj).map(function(_) { return jsonObj[_]; });

            delete obj[e.target.value];

            // this.filesListFresh = obj;

            console.log("this.filesListFresh===>", typeof this.filesListFresh, this.filesListFresh);

        },
        getFormatCode(strValue){
            return strValue.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>').replace(/\s/g, ' ');
        },
        //送出表單前驗證
        validateBeforeSubmit() {
            this.isChanged = true;
            // 找到第一個有錯誤的欄位並將畫面捲到該欄位位置
            let errorField = document.querySelector('.error');
            if (
                this.ReportIdentity == "" || 
                this.ReportCategory == "" ||
                this.Email == "" ||
                this.ReportPlace == "" ||
                this.ReportTime == "" ||
                this.ReportObject == "" ||
                this.ReportOrg == "" ||
                this.ReportIssue == "" ||
                this.ReportContent == ""
            ) {
                this.isOpenErrorFeedback = true;
                errorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            } else {
                this.onSubmit();
            }
        },
        // 送出表單
		onSubmit(values) {
        //onSubmit(values, { resetForm } ) {
            // if (typeof args !== "object" || args === null) {
            //     // args = { resetForm };
            //     args = {};
            // }

            this.uploadFile();

            this.isCollapse = true;
            var FD = new FormData();
            FD.append('mode', 'add');
            var ReportCategory = this.ReportCategory=='' ? this.ReportCategoryData[0] : this.ReportCategory;
            var objData ={
                "class": "kpo-report-mailbox",
                "lang": this.$route.params.lang,
                "ReportIdentity": this.ReportIdentity,
                "ReportCategory": this.ReportCategory,
                "Name": this.Name,
                "Phone": this.Phone,
                "Email": this.Email,
                "ReportPlace": this.ReportPlace,
                "ReportTime": this.ReportTime,
                "ReportObject": this.ReportObject,
                "ReportOrg": this.ReportOrg,
                "ReportIssue": this.ReportIssue,
                "UploadFile_1": this.UploadFile_1,
                "UploadFile_2": this.UploadFile_2,
                "UploadFile_3": this.UploadFile_3,
                "UploadFile_4": this.UploadFile_4,
                "ReportContent": this.getFormatCode(this.ReportContent),  
                "RandCode": Math.random(),
                "send_server": "https://event.xyzprinting.com/Tp/SendMAIL/",
                "send_email": this.ReportCategoryEmail[ReportCategory],
                "send_name": "KPO",
                "send_trigger": "KPOTwReportMailbox_"+this.$route.params.lang
            };
            FD.append('data',JSON.stringify(objData));
            console.log("objData=>", objData);
            this.axios({
                method: 'post',
                url: 'https://nkg-web-api.japaneast.cloudapp.azure.com/Form/',
                data: FD,
                headers: {'Content-Type': 'multipart/form-data' }
            })
            .then(response => {
                if (response.status == 200) {
                    this.isCollapse = false;
                    this.isOpenSuccessFeedback = true;
                    resetForm();
                    this.ReportCategory='';
                    this.ReportCategoryData=[];

                    // let sendMsg = this.$route.params.lang=='zh-TW' ? '送出成功' : 'Sent successfully';
                    // alert(sendMsg);
                } else {	
                    this.isCollapse=false;
                    let sendMsg = this.$route.params.lang=='zh-TW' ? '送出失敗' : 'Failed to send';
                    alert(sendMsg);
                }
            })
            .catch(error => {
                // console.log(error);
            });
		}
    },
};
</script>

<style lang="scss">
.hidden {
    opacity: 0;
    transform: translateY(-20%);
    transition: all 200ms ease;
}
.user-info {
    padding: 40px;
}
.report-info {
    background: #003271;
    border-radius: 14px;
    padding: 40px;
}
.filebox {
    width: 250px;
    height: 50px;
    margin: 10px 20px 6px 1px;
    padding: 13px 10px 11px 25px;
    border-radius: 20px;
    background: #a1dbff;
    display: flex;
    justify-content: space-between;
    &:last-child {
        margin-right: 0;
    }
    span {
        display: block;
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
.files-list {
    display: flex;
    flex-flow: wrap;
    padding: 0;
    margin-bottom: 46px;
}
.btn-remove {
    background: transparent;
    border: none;
}

.scrollBox {
    width: 100%;
    height: 430px;
    padding-left: 70px;
    padding-right: 70px;
    text-align: justify;
    overflow-y: scroll;
}
.scrollBox p,
.scrollBox span,
.scrollBox ul li {
    font-size: 16px;
}
.scrollBox {
    &::-webkit-scrollbar{
        -webkit-appearance: none;
        height: 6px;
        width: 6px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-track {
        border-radius: 5px;
    }
    &:hover::-webkit-scrollbar-track {
        background-color: #e8e8e8;
    }
    &::-webkit-scrollbar-thumb{
        border-radius: 5px;
        background-color: rgba(61, 61, 61, .7);
    }
}
.lang-en .scrollBox {
    text-align: left;
}
.popup__title {
    color: #134a8f;
    font-size: 24px;
    text-align: center;
    margin-bottom: 50px;
}
.rule {
    font-weight: 900;
}
.custom-checkbox {
    width: 24px;
    height: 24px;
}
.btn-size-240 {
    width: 240px;
    height: 48px;
    padding: 12px 28px;
}
.feedback-popup .popup__content {
    width: 100%;
    max-width: 700px;
    margin: auto;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
}
@media (max-width: 992px) {
    .popup__content {
        padding-right: 10px;
    }
    .scrollBox {
        padding: 0;
        padding-right: 20px;
    }
}
@media (max-width: 768px) {
    .user-info {
        padding: 0 14px;
    }
    .report-info {
        padding: 30px 14px;
    }
    .files-list {
        margin-bottom: 24px;
    }
}
@media (max-width: 697px) {
    .filebox {
        width: 100%;
        margin-right: 0;
    }
}

@media (max-width: 576px) {
    .popup__content .nkg__wrap {
        margin-top: 1.5rem;
    }
    .popup__title {
        margin-bottom: 30px;
    }
    .scrollBox {
        height: 380px;
    }
    .lang-en .form-floating > label {
        line-height: 1;
    }
    .lang-en .paddingtop-control {
        padding-top: 12px;
    }
    .lang-en #ReportTime .multiline-h-control {
        height: 74px;
    }
    .lang-en #ReportTime .form-control {
        padding-top: 38px;
    }
}

@media (max-width: 400px) {
    .lang-en .height-control {
        height: 74px;
    }
}
</style>